<template>
  <div data-app>
    <v-progress-linear indeterminate color="cyan"></v-progress-linear>
    <div class="row">
      <div class="col-md-12">
        <div class="v-card v-sheet">
          <div class="row">
            <div class="col-lg-4 col-12 pl-0">
              <div class="bg-white p-0" style="height: 100%">
                <div class="py-4 px-4 border_b_silver col-12">
                  <span class="card-label font-weight-bolder Main_Blue"
                    >Professional summary</span
                  >
                </div>
                <div class="py-2 px-4">
                  <textarea
                    name="input-7-4"
                    maxlength="600"
                    v-model="about"
                    label="About"
                    class="form-control"
                    style="border-radius: 0"
                    id="exampleFormControlTextarea1"
                    rows="18"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-12 p-0">
              <div class="bg-white row p-0 m-0 col-12">
                <div
                  class="
                    py-2
                    px-4
                    border_b_silver
                    d-flex
                    justify-content-between
                  "
                >
                  <span class="card-label pt-2 font-weight-bolder Main_Blue"
                    >Job details</span
                  >
                  <a
                    class="custom-submit-button btn_submit__ch111"
                    href="/custom-pages/profile"
                  >
                    <span>Edit personal information</span>
                  </a>
                </div>
                <div class="col-4">
                  <v-combobox
                    v-model="current_salary"
                    :items="desiredSalaryOptions"
                    label="Current salary*"
                    size="sm"
                    class="border__input_ch"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-4 p-0">
                  <v-combobox
                    v-model="desired_salary"
                    :items="desiredSalaryOptions"
                    label="Desired Salary*"
                    size="sm"
                    class="border__input_ch"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-4">
                  <v-combobox
                    v-model="city"
                    :items="cityOptions"
                    label="Current Location"
                    size="sm"
                    class="border__input_ch"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <!-- <div class="col-4">
                  <label style="font-weight: bold">Current Location</label>
                  <b-form-select
                    v-model="city"
                    :options="cityOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select>
                </div> -->
                <div class="col-4">
                  <v-combobox
                    v-model="interestedIn"
                    :items="interestedInOptions"
                    label="Job Shifts"
                    multiple
                    size="sm"
                    class="border__input_ch"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-4 p-0">
                  <v-combobox
                    v-model="cities"
                    :items="cityOptions"
                    label="Willing to relocate"
                    multiple
                    size="sm"
                    class="border__input_ch"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-4">
                  <v-checkbox
                    class="checkbox_ch"
                    style="margin-top: 8%"
                    v-model="available_for_job"
                    label="Available for Job?"
                  ></v-checkbox>
                </div>
                <div class="col-6">
                  <v-text-field
                    class="border__input_ch"
                    v-model="website"
                    type="text"
                    label="Website link"
                    required
                  ></v-text-field>
                </div>
                <div class="col-6">
                  <v-text-field
                    class="border__input_ch"
                    label="LinkedIn profile"
                    v-model="linkedin"
                    type="text"
                    required
                  ></v-text-field>
                </div>
              </div>
              <div class="row mt-4 px-0" style="padding-left: 24px !important">
                <div class="row bg-white px-4">
                  <div class="col-12">
                    <div
                      class="row"
                      v-for="(item, index) in skillsArr"
                      :key="index"
                    >
                      <div class="col-md-4 pl-0">
                        <v-combobox
                          v-model="item.job_funcation_ch"
                          :items="job_funcation_ch_Options"
                          size="sm"
                          class="border__input_ch"
                          label="Profession*"
                          chips
                          @change="getProfessions(data.selected)"
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                      <div class="col-md-3 p-0">
                        <v-combobox
                          v-model="item.skill"
                          :items="skillsOptions"
                          size="sm"
                          class="border__input_ch"
                          label="Skills"
                          chips
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>

                      <div class="col-md-3 pr-0">
                        <v-text-field
                          class="border__input_ch"
                          label="Experience in years"
                          type="number"
                          v-model="item.experience"
                        ></v-text-field>
                      </div>

                      <div
                        class="col-md-1"
                        style="display: flex; margin-top: 2%; margin-left: 0%"
                      >
                        <v-btn
                          class="custom-add-new-record-button btn_row_ch"
                          type="button"
                          @click="addNewRowSkill()"
                          >Add</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button btn_row_ch"
                          type="button"
                          @click="deleteRowSkill(index)"
                          >Del</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="2">
                          <div
                            class="row"
                            v-for="(item, index) in languageSkillsArr"
                            :key="index"
                          >
                            <div class="col-md-5 p-0">
                              <v-combobox
                                v-model="item.language"
                                :items="languageOptions"
                                size="sm"
                                class="border__input_ch"
                                label="Language"
                                chips
                              >
                                <template>
                                  <v-chip
                                    :key="JSON.stringify(data.item)"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    :disabled="data.disabled"
                                    @click:close="
                                      data.parent.selectItem(data.item)
                                    "
                                  >
                                    {{ data.item }}
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </div>

                            <div class="col-md-5 pr-0">
                              <v-combobox
                                v-model="item.level"
                                :items="levelOptions"
                                size="sm"
                                class="border__input_ch"
                                label="Proficiency"
                                chips
                              >
                                <template>
                                  <v-chip
                                    :key="JSON.stringify(data.item)"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    :disabled="data.disabled"
                                    @click:close="
                                      data.parent.selectItem(data.item)
                                    "
                                  >
                                    {{ data.item }}
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </div>

                            <div
                              class="col-md-1"
                              style="
                                display: flex;
                                margin-top: 1.9%;
                                margin-left: 0%;
                              "
                            >
                              <v-btn
                                class="custom-add-new-record-button btn_row_ch"
                                type="button"
                                @click="addNewRowSkill1()"
                                >Add</v-btn
                              >
                              <v-btn
                                v-if="index > 0"
                                class="
                                  custom-delete-new-record-button
                                  btn_row_ch
                                "
                                type="button"
                                @click="deleteRowSkill1(index)"
                                >Del</v-btn
                              >
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0">
        <!-- create form start -->
        <v-card class="rounded-0">
          <div class="mt-5 rounded-0" style="padding: 1%">
            <v-form ref="form" @submit.prevent="submit" class="custom-form">
              <div class="row d-none">
                <div class="col-md-2">
                  <img
                    style="border-radius: 8px; width: 160px; height: 160px"
                    :src="url"
                  />
                  <v-file-input
                    style="font-size: 8px"
                    label="Profile picture"
                    @change="Preview_image"
                    v-model="image"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-9">
                  <div class="row">
                    <!-- <div class="col-md-4" style="margin-top: 1%">
                      <label>Areas of Expertise:</label>
                      <b-form-select
                        v-model="profession"
                        :options="professionOptions"
                        size="sm"
                        class="mt-3"
                        required
                      ></b-form-select>
                    </div> -->
                    <!-- <div class="col-md-4">
                      <v-combobox
                        v-model="areaOfExpertise"
                        :items="areaOfExpertiseOptions"
                        label="Job Functions"
                        multiple
                        size="sm"
                        class="mt-3"
                        chips
                        

                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div> -->
                    <div class="col-md-6">
                      <v-combobox
                        v-model="interestedIn"
                        :items="interestedInOptions"
                        label="Job Shifts"
                        multiple
                        size="sm"
                        class="mt-3"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <div class="col-md-6">
                      <v-combobox
                        v-model="cities"
                        :items="cityOptions"
                        label="Willing to relocate"
                        multiple
                        size="sm"
                        class="mt-3"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <!-- <v-text-field
                        type="number"
                        v-model="current_salary"
                        label="Current salary"
                      ></v-text-field> -->
                      <v-combobox
                        v-model="current_salary"
                        :items="desiredSalaryOptions"
                        label="Current salary"
                        size="sm"
                        class="mt-3"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>

                    <div class="col-md-6">
                      <v-combobox
                        v-model="desired_salary"
                        :items="desiredSalaryOptions"
                        label="Desired Salary*"
                        size="sm"
                        class="mt-3"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>

                    <div class="col-md-6">
                      <v-checkbox
                        class="checkbox_ch"
                        style="margin-top: 8%"
                        v-model="available_for_job"
                        label="Available for Job?"
                      ></v-checkbox>
                    </div>

                    <div class="col-md-6">
                      <v-text-field
                        v-model="cnic"
                        type="text"
                        label="CNIC"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-none">
                <div class="col-md-12">
                  <v-card elevation="2" style="padding: 2%">
                    <div
                      class="row"
                      :style="
                        index == 0
                          ? 'margin-top: 0%; '
                          : 'margin-top: 2%; ' +
                            'padding-left: 1%; padding-right: 1%'
                      "
                      v-for="(item, index) in skillsArr"
                      :key="index"
                    >
                      <div class="col-md-4">
                        <label style="font-weight: bold">Profession*</label>
                        <!-- <b-form-select
                          v-model="item.job_funcation_ch"
                          :options="job_funcation_ch_Options"
                          size="sm"
                          class="mt-3"
                          required
                          @change="getProfessions()"
                        ></b-form-select> -->
                        <v-combobox
                          v-model="item.job_funcation_ch"
                          :items="job_funcation_ch_Options"
                          size="sm"
                          class="mt-3 profession_slect_box_ch"
                          style="margin: 0 !important; padding: 0 !important"
                          chips
                          @change="getProfessions(data.selected)"
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                      <div class="col-md-3">
                        <label style="font-weight: bold">Skills*</label>
                        <!-- <b-form-select
                          v-model="item.skill"
                          :options="skillsOptions"
                          size="sm"
                          class="mt-3"
                          required
                        ></b-form-select> -->
                        <v-combobox
                          v-model="item.skill"
                          :items="skillsOptions"
                          size="sm"
                          class="mt-3 profession_slect_box_ch"
                          style="margin: 0 !important; padding: 0 !important"
                          chips
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>

                      <div class="col-md-3">
                        <label style="font-weight: bold; margin: 0"
                          >Experience in years*</label
                        >
                        <v-text-field
                          type="number"
                          v-model="item.experience"
                        ></v-text-field>
                      </div>

                      <div
                        class="col-md-1"
                        style="display: flex; margin-top: 2%; margin-left: 2%"
                      >
                        <v-btn
                          class="custom-add-new-record-button"
                          type="button"
                          @click="addNewRowSkill()"
                          >+</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button"
                          type="button"
                          @click="deleteRowSkill(index)"
                          >-</v-btn
                        >
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="row d-none" style="margin-top: 2%">
                <div class="col-md-12">
                  <v-card elevation="2" style="padding: 2%">
                    <div
                      class="row"
                      :style="
                        index == 0
                          ? 'margin-top: 0%; '
                          : 'margin-top: 2%; ' +
                            'padding-left: 1%; padding-right: 1%'
                      "
                      v-for="(item, index) in languageSkillsArr"
                      :key="index"
                    >
                      <div class="col-md-3">
                        <label style="font-weight: bold">Language</label>
                        <b-form-select
                          v-model="item.language"
                          :options="languageOptions"
                          size="sm"
                          class="mt-3"
                          required
                        ></b-form-select>
                      </div>

                      <div class="col-md-3">
                        <label style="font-weight: bold">Chose</label>
                        <b-form-select
                          v-model="item.level"
                          :options="levelOptions"
                          size="sm"
                          class="mt-3"
                          required
                        ></b-form-select>
                      </div>

                      <div
                        class="col-md-1"
                        style="display: flex; margin-top: 2%; margin-left: 2%"
                      >
                        <v-btn
                          class="custom-add-new-record-button"
                          type="button"
                          @click="addNewRowSkill1()"
                          >+</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button"
                          type="button"
                          @click="deleteRowSkill1(index)"
                          >-</v-btn
                        >
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="row d-none" style="margin-top: 3%; margin-bottom: 2%">
                <div class="col-md-3">
                  <v-text-field
                    v-model="dob"
                    label="Date of birth"
                    prepend-icon="mdi-calendar"
                    type="date"
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    v-model="website"
                    type="text"
                    label="Website link"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    v-model="linkedin"
                    type="text"
                    label="LinkedIn profile"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: -1%">
                  <label style="font-weight: bold">Current Location</label>
                  <b-form-select
                    v-model="city"
                    :options="cityOptions"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <v-card elevation="2">
                    <div class="col-12">
                      <span class="card-label font-weight-bolder Main_Blue"
                        >Work experience</span
                      >
                    </div>
                    <div
                      class="row"
                      :style="
                        index == 0
                          ? 'margin-top: 0%; '
                          : 'margin-top: 2%; ' + ''
                      "
                      v-for="(item, index) in workExperience"
                      :key="index"
                    >
                      <div class="col-md-3">
                        <v-text-field
                          v-model="item.companyName"
                          class="border__input_ch"
                          label="Company Name"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3 pl-0">
                        <v-text-field
                          v-model="item.designation"
                          class="border__input_ch"
                          label="Designation"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.startingDate"
                          class="border__input_ch"
                          label="Starting date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.endDate"
                          class="border__input_ch"
                          label="End date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div
                        class="col-md-1 pl-0"
                        style="display: flex; margin-top: 1.2%; margin-left: 0%"
                      >
                        <v-btn
                          class="custom-add-new-record-button btn_row_ch"
                          type="button"
                          @click="addNewRow()"
                          >Add</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button btn_row_ch"
                          type="button"
                          @click="deleteRow(index)"
                          >Del</v-btn
                        >
                      </div>
                      <div class="col-md-12">
                        <vue-editor
                          v-model="item.description"
                          outlined
                          name="input-7-4"
                          class="rounded-0"
                          label="Job description"
                          rows="2"
                          style="min-height: 84px"
                        ></vue-editor>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="row" style="margin-top: 2%">
                <div class="col-md-12 p-1">
                  <div class="col-12">
                    <span class="card-label font-weight-bolder Main_Blue"
                      >Education</span
                    >
                  </div>
                  <v-card elevation="2" style="padding: 1%">
                    <div
                      class="row"
                      :style="
                        index == 0
                          ? 'margin-top: 0%; '
                          : 'margin-top: 2%; ' + ''
                      "
                      v-for="(item, index) in education"
                      :key="index"
                    >
                      <div class="col-md-3">
                        <v-combobox
                          v-model="item.university"
                          :items="universities"
                          size="sm"
                          class="border__input_ch"
                          label="University"
                          chips
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                      <div class="col-md-3 pl-0">
                        <v-combobox
                          v-model="item.degree"
                          :items="degrees"
                          size="sm"
                          class="border__input_ch"
                          label="Degree"
                          chips
                        >
                          <template>
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.startingDate"
                          class="border__input_ch"
                          label="Starting date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.endDate"
                          class="border__input_ch"
                          label="End date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div
                        class="col-md-1 pl-0"
                        style="display: flex; margin-top: 1.2%; margin-left: 0%"
                      >
                        <v-btn
                          class="custom-add-new-record-button btn_row_ch"
                          type="button"
                          @click="addNewEducationRow()"
                          >Add</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button btn_row_ch"
                          type="button"
                          @click="deleteEducationRow(index)"
                          >Del</v-btn
                        >
                      </div>
                      <div class="col-md-12">
                        <vue-editor
                          v-model="item.details"
                          outlined
                          name="input-7-4"
                          label="Degree details"
                          rows="2"
                          style="min-height: 84px"
                        ></vue-editor>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="row" style="margin-top: 2%">
                <div class="col-md-12">
                  <v-card elevation="2">
                    <div class="col-12">
                      <span class="card-label font-weight-bolder Main_Blue"
                        >Certificate</span
                      >
                      <!-- {{ certificates }} -->
                    </div>
                    <div
                      class="row"
                      :style="
                        index == 0
                          ? 'margin-top: 0%; '
                          : 'margin-top: 2%; ' + ''
                      "
                      v-for="(item, index) in certificates"
                      :key="index"
                    >
                      <div class="col-md-3">
                        <v-text-field
                          v-model="item.certificateName"
                          class="border__input_ch"
                          label="Certificate name"
                        ></v-text-field>
                      </div>
                      <div class="col-md-3 pl-0">
                        <v-text-field
                          v-model="item.awardedBy"
                          class="border__input_ch"
                          label="Awarded by"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.startingDate"
                          class="border__input_ch"
                          label="Starting date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div class="col-md-2 pl-0">
                        <v-text-field
                          v-model="item.endDate"
                          class="border__input_ch"
                          label="End date"
                          type="date"
                        ></v-text-field>
                      </div>
                      <div
                        class="col-md-1"
                        style="display: flex; margin-top: 1.2%; margin-left: 0%"
                      >
                        <v-btn
                          class="custom-add-new-record-button btn_row_ch"
                          type="button"
                          @click="addNewCertificateRow()"
                          >Add</v-btn
                        >
                        <v-btn
                          v-if="index > 0"
                          class="custom-delete-new-record-button btn_row_ch"
                          type="button"
                          @click="deleteCertificateRow(index)"
                          >Dell</v-btn
                        >
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <v-card elevation="2 d-none" style="padding: 2%">
                <label style="font-weight: bold">Security Questions</label>
                <div
                  class="row"
                  :style="
                    index == 0
                      ? 'margin-top: 0%; '
                      : 'margin-top: 2%; ' +
                        'padding-left: 1%; padding-right: 1%'
                  "
                  v-for="(item, index) in security_questions"
                  :key="index"
                >
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.question"
                      label="Question"
                    ></v-text-field>
                  </div>
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.answer"
                      label="Answer"
                    ></v-text-field>
                  </div>
                  <div
                    class="col-md-1"
                    style="display: flex; margin-top: 2%"
                    v-if="security_questions.length < 2"
                  >
                    <v-btn
                      class="custom-add-new-record-button v-size--small"
                      type="button"
                      @click="addNewRow1()"
                      >+</v-btn
                    >
                    <v-btn
                      v-if="index > 0"
                      class="custom-delete-new-record-button v-size--small"
                      type="button"
                      @click="deleteRow1(index)"
                      >-</v-btn
                    >
                  </div>
                </div>
              </v-card>

              <!-- <div class="row" style="margin-top: 2%">
                <div class="col-md-12">
                  <label><b>Professional Summary</b></label>
                  <textarea
                    name="input-7-4"
                    maxlength="600"
                    v-model="about"
                    label="About"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="6"
                  ></textarea>
                </div>
              </div> -->
              <!-- <div
                class="row"
                style="
                  margin-top: 2%;
                  display: flex;
                  flex-direction: column;
                  padding-left: 1.8%;
                "
              >
                <label>Terms & Conditions</label>
                <v-checkbox
                  v-model="checkbox"
                  label="I accept the Terms & Conditions and Privacy policy"
                ></v-checkbox>
              </div> -->

              <v-card-actions>
                <v-btn class="custom-submit-button btn_submit__ch" type="submit"
                  ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                  <span>Update</span>
                </v-btn>
                <v-btn
                  text
                  @click="cancel"
                  class="custom-cancel-button btn_submit__ch_cls"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import GeneralService from "@/MainServices/GeneralService.js";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      url: "../images/profile_ch.jpg",
      // url: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      image: null,
      form: [""],
      search: null,
      loading: true,
      success: false,
      cnic: "",
      user_id:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      submitting: false,
      email:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).email
          : "",
      about: "",
      checkbox: false,
      rules: {
        job_funcation_ch: [
          (val) => (val || "").length > 0 || "Company name is required",
        ],
      },
      data: [],

      security_questions: [{ question: "", answer: "" }],

      // profession: "",
      // professionOptions: [],

      job_funcation_ch: null,
      job_funcation_ch_Options: [],

      areaOfExpertise: null,
      areaOfExpertiseOptions: [],

      interestedIn: null,
      interestedInOptions: [],

      dob: "",
      linkedin: "",
      website: "",
      current_salary: "",
      available_for_job: 0,
      city: null,
      cityOptions: [],
      cities: [],
      desired_salary: "",
      desiredSalaryOptions: [
        "Upto 20,000",
        "20,001 - 40,000",
        "40,001 - 60,000",
        "60,001 - 80,000",
        "80,001 - 100,000",
        "100,001 - 125,000",
        "125,001 - 150,000",
        "150,001 - 175,000",
        "175,001 - 225,000",
        "225,001 - 275,000",
        "275,001 - 300,000",
        "300,000 - 350,000",
        "350,000 +",
      ],
      skillsArr: [
        {
          job_funcation_ch: "",
          skill: "",
          experience: "",
        },
      ],

      languageOptions: ["English", "Urdu"],
      levelOptions: ["Basic", "Conversational", "Native"],
      languageSkillsArr: [
        {
          language: "",
          level: "",
        },
      ],

      workExperience: [
        {
          companyName: "",
          designation: "",
          startingDate: "",
          endDate: "",
          description: "",
        },
      ],

      universities: [],
      degrees: [],
      education: [
        {
          university: null,
          degree: null,
          startingDate: "",
          endDate: "",
          details: "",
        },
      ],

      certificates: [
        { certificateName: "", awardedBy: "", startingDate: "", endDate: "" },
      ],

      skills: null,
      skillsOptions: [],
    };
  },

  components: {
    VueEditor,
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    getInititalData() {
      GeneralService.getSingleJobSeekerProfileWithData(this.user_id)
        .then((res) => {
          var _res = res.data.profile;

          // fetch professions
          // res.data.professions.forEach((element) => {
          //   this.professionOptions.push({
          //     value: element.profession_name,
          //     text: element.profession_name,
          //   });
          // });
          // this.professionOptions.unshift(
          //   ...[{ value: "", text: "Profession" }]
          // );

          // fetch expertise
          // res.data.jobFunction.forEach((element) => {
          //   this.areaOfExpertiseOptions.push(element.job_function_name);
          //   //console.log(element.job_function_name);
          // });

          // fetch job_funcation_ch
          res.data.jobFunction.forEach((element) => {
            this.job_funcation_ch_Options.push({
              value: element.job_function_name,
              text: element.job_function_name,
            });
          });
          this.job_funcation_ch_Options.unshift(
            ...[{ value: "", text: "Select Profession" }]
          );

          if (_res.security_questions && _res.security_questions.length > 0) {
            this.security_questions = _res.security_questions;
          }

          // interested options
          res.data.jobShifts.forEach((element) => {
            this.interestedInOptions.push(element.job_shift);
          });

          // cities
          res.data.cities.forEach((element) => {
            this.cityOptions.push(element.city_name);
          });
          // this.cityOptions.unshift(
          //   ...[{ value: "current location", text: "current location" }]
          // );

          // fetch universities
          res.data.universities.forEach((element) => {
            this.universities.push({
              value: element.university_name,
              text: element.university_name,
            });
          });
          this.universities.unshift(...[{ value: null, text: "University" }]);

          // fetch degrees
          res.data.degreeLevel.forEach((element) => {
            this.degrees.push({
              value: element.degree_level,
              text: element.degree_level,
            });
          });
          this.degrees.unshift(...[{ value: null, text: "Degree" }]);

          // fetch skills
          res.data.skills.forEach((element) => {
            this.skillsOptions.push({
              value: element.skill_name,
              text: element.skill_name,
            });
          });
          this.skillsOptions.unshift(...[{ value: "", text: "Select Skill" }]);

          if (_res) {
            if (_res.profile_pic && _res.profile_pic.length > 0) {
              this.url = this.mediaUrl + _res.profile_pic;
            }
            // if (_res.profession && _res.profession.length > 0) {
            //   // this.profession = _res.profession;
            // }

            this.available_for_job = _res.available_for_job;

            if (_res.details && _res.details.length > 0) {
              this.about = _res.details;
            }
            if (_res.area_of_expertise && _res.area_of_expertise.length > 0) {
              this.areaOfExpertise = _res.area_of_expertise;
            }
            if (_res.interested_in && _res.interested_in.length > 0) {
              this.interestedIn = _res.interested_in;
            }
            if (_res.dob && _res.dob.length > 0) {
              this.dob = _res.dob;
            }
            if (_res.cnic && _res.cnic.length > 0) {
              this.cnic = _res.cnic;
            }
            if (_res.current_salary == "null") {
              this.current_salary = "";
            } else {
              this.current_salary = _res.current_salary;
            }

            if (_res.linkedin && _res.linkedin.length > 0) {
              this.linkedin = _res.linkedin;
            }
            if (_res.website && _res.website.length > 0) {
              this.website = _res.website;
            }
            if (_res.city && _res.city.length > 0) {
              this.city = _res.city;
            }
            if (_res.desired_salary == "null") {
              this.desired_salary = "";
            } else {
              this.desired_salary = _res.desired_salary;
            }

            if (
              _res.willing_to_relocate &&
              _res.willing_to_relocate.length > 0
            ) {
              this.cities = _res.willing_to_relocate;
            }
            if (_res.skills && _res.skills.length > 0) {
              this.skillsArr = _res.skills;
            }
            if (_res.language_skills && _res.language_skills.length > 0) {
              this.languageSkillsArr = _res.language_skills;
            }
            if (_res.education && _res.education.length > 0) {
              this.education = _res.education;
            }
            if (_res.work_experience && _res.work_experience.length > 0) {
              this.workExperience = _res.work_experience;
            }
            if (_res.certificate && _res.certificate.length > 0) {
              // console.log("certificates", _res.certificate);
              this.certificates = _res.certificate;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addNewRow() {
      this.workExperience.push({
        companyName: "",
        designation: "",
        startingDate: "",
        endDate: "",
        description: "",
      });
    },
    deleteRow(index) {
      var temp = [];
      this.workExperience.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.workExperience = temp;
    },

    addNewRow1() {
      this.security_questions.push({
        question: "",
        answer: "",
      });
    },
    deleteRow1(index) {
      var temp = [];
      this.security_questions.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.security_questions = temp;
    },

    addNewRowSkill() {
      this.skillsArr.push({
        job_funcation_ch: "",
        skill: "",
        experience: "",
      });
    },
    deleteRowSkill(index) {
      var temp = [];
      this.skillsArr.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.skillsArr = temp;
    },
    addNewRowSkill1() {
      this.languageSkillsArr.push({
        language: "",
        level: "",
      });
    },
    deleteRowSkill1(index) {
      var temp = [];
      this.languageSkillsArr.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.languageSkillsArr = temp;
    },
    addNewEducationRow() {
      this.education.push({
        university: null,
        degree: null,
        startingDate: "",
        endDate: "",
        score: "",
      });
    },
    deleteEducationRow(index) {
      var temp = [];
      this.education.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.education = temp;
    },
    addNewCertificateRow() {
      this.certificates.push({
        certificateName: "",
        awardedBy: "",
        startingDate: "",
        endDate: "",
      });
    },
    deleteCertificateRow(index) {
      var temp = [];
      this.certificates.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.certificates = temp;
    },
    submit() {
      // console.log("This is profession", this.skillsArr[0].skill.text);
      if (
        this.skillsArr[0].job_funcation_ch === "" ||
        this.skillsArr[0].job_funcation_ch === null
      ) {
        Swal.fire({
          title: "",
          text: "Please select your Profession*",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.skillsArr[0].skill === "" || this.skillsArr[0].skill === null || this.skillsArr[0].skill.text == 'Select Skill') {
        Swal.fire({
          title: "",
          text: "Please add your skill*",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (
        this.skillsArr[0].experience === "" ||
        this.skillsArr[0].experience === null
      ) {
        Swal.fire({
          title: "",
          text: "Please add your experience*",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.current_salary === "" || this.current_salary === null) {
        Swal.fire({
          title: "",
          text: "Please select Current Salary*",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.desired_salary === "" || this.desired_salary === null) {
        Swal.fire({
          title: "",
          text: "Please select Desired Salary*",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }

      this.submitting = true;
      let formData = new FormData();
      formData.append("profile_pic", this.image);
      formData.append("available_for_job", this.available_for_job ? 1 : 0);
      formData.append("profession", this.job_funcation_ch);
      formData.append("details", this.about);
      formData.append("desired_salary", this.desired_salary);
      formData.append("area_of_expertise", this.areaOfExpertise);
      formData.append("interested_in", this.interestedIn);
      formData.append("dob", this.dob);
      formData.append("cnic", this.cnic);
      formData.append("linkedin", this.linkedin);
      formData.append("current_salary", this.current_salary);
      formData.append("website", this.website);
      formData.append("city", this.city);
      formData.append("willing_to_relocate", this.cities);
      formData.append(
        "security_questions",
        JSON.stringify(this.security_questions)
      );
      formData.append("skills", JSON.stringify(this.skillsArr));
      formData.append(
        "language_skills",
        JSON.stringify(this.languageSkillsArr)
      );
      formData.append("education", JSON.stringify(this.education));
      formData.append("work_experience", JSON.stringify(this.workExperience));
      formData.append("certificate", JSON.stringify(this.certificates));

      axios.defaults.headers.common["Authorization"] = this.token;

      axios
        .post(`${API_URL}/job-seeker-profile/${this.user_id}`, formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Profile updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .catch(() => {
          this.loading = false;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getProfessions() {
      let names = this.skillsArr;
      var temp = [];
      this.skillsArr.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.skillsArr = temp;
      console.log("temp", temp);
      axios
        .post(`${API_URL}/get-profession/`, { names })
        .then((res) => {
          // this.loading = false;
          // this.success = true;
          // this.submitting = false;
          // Swal.fire({
          //   title: "",
          //   text: "Profile updated",
          //   icon: "success",
          //   confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
          // });
        })
        .catch(() => {
          // this.loading = false;
          // this.submitting = false;
          // Swal.fire({
          //   title: "",
          //   text: " went wrong!",
          //   icon: "error",
          //   confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
          // });
        });
    },
    cancel() {
      this.$router.push({ name: "dashboard" });
    },
    resetForm() {
      this.form = [""];
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
  },
};
</script>
