import ApiInstance from './ApiInstance.js'

export default {
  
  getJobFunctions() {
    return ApiInstance({ requiresAuth: true }).get('/job-function');
  },
  getProfessions() {
    return ApiInstance({ requiresAuth: true }).get('/profession');
  },
  getJobShifts() {
    return ApiInstance({ requiresAuth: true }).get('/job-shift');
  },
  getCities() {
    return ApiInstance({ requiresAuth: true }).get('/cities');
  },
  getUniversities() {
    return ApiInstance({ requiresAuth: true }).get('/universities');
  },
  getDegrees() {
    return ApiInstance({ requiresAuth: true }).get('/degree-level');
  },
  getSkills() {
    return ApiInstance({ requiresAuth: true }).get('/skill');
  },
  getSingleJobSeekerProfile(id){
    return ApiInstance({ requiresAuth: true }).get(`job-seeker-profile/${id}`);
  },
  getSingleJobSeekerProfileWithData(id){
    return ApiInstance({ requiresAuth: true }).get(`job-seeker-profile-with-data/${id}`);
  },
  getSingleJobSeekerPWD(id){
    return ApiInstance({ requiresAuth: false }).get(`job-seeker-profile-with-data/${id}`);
  },
  getjobs(){
    return ApiInstance({ requiresAuth: false }).get(`get-jobs`);
  },
  getJobCareerLevels(){
    return ApiInstance({ requiresAuth: false }).get(`get-job-career-levels`);
  }
}
